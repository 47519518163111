import './eventCard.css'

import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Badge,
  IconButton,
  Typography,
  Chip,
  Box,
  Tooltip,
} from '@mui/material'
import {
  CalendarToday as CalendarTodayIcon,
  Close as CloseIcon,
  LocationOn as LocationOnIcon,
  ArrowUpward as ArrowUpwardIcon,
} from '@mui/icons-material/'

import eventPlaceholderImage from './images/logo_icon_192.png'
import { getEventImage, formatEventDateRange } from './utils'

const EventCardPreview = ({
  id,
  title,
  image_url,
  horizontal,
  date,
  start_date,
  end_date,
  location,
  city,
  onRemoveEvent,
  priority,
  responded,
}) => {
  const [ placeholderImage, setPlaceholderImage ] = useState(false)
  const to = `/event/${id}`
  const eventStartDate = useMemo(() => start_date || date, [ start_date, date ])

  const content = useMemo(() => (
    <>
      <Typography variant='body1' className='event-card-title'>{title}</Typography>
      {/* <div className="priority-container">{priority}</div> */}
      {(location || city) && (
        <div className="location-container">
          <LocationOnIcon fontSize='small' />
          <span>{location || city}</span>
        </div>
      )}
      <div className="datetime-container">
        <CalendarTodayIcon fontSize='small' />
        <span>{eventStartDate ? formatEventDateRange(eventStartDate, end_date) : ''}</span>
      </div>
    </>
  ), [ title, location, city, eventStartDate, end_date ])

  const onImageError = (e) => {
    setPlaceholderImage(true)
    e.target.src = eventPlaceholderImage
  }

  const imageSrc = useMemo(() => getEventImage(image_url), [ image_url ])

  return (
    <Badge color="secondary" badgeContent={priority} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
      <Link
        className={`event-card ${horizontal ? 'horizontal' : ''}`}
        to={to}
      >
        {onRemoveEvent && (
          <IconButton
            onClick={e => {
              e.preventDefault()
              onRemoveEvent()
            }}
            title='Remove event'
            size='small'
            sx={{
              position: 'absolute',
              top: 2,
              right: 2,
              zIndex: 2,
              bgcolor: 'error.dark',
              '&:hover': { bgcolor: 'error.main' }
            }}
            variant='contained'
          >
            <CloseIcon />
          </IconButton>
        )}

        <div className={`event-image-wrapper ${(!image_url || placeholderImage) ? 'placeholder-image' : ''}`}>
          <img
            src={imageSrc}
            className='event-image'
            alt={title}
            onError={onImageError}
          />

          <Box sx={{ position: 'absolute', left: 4, bottom: 2, fontWeight: 'bold' }}>
            <Tooltip title={`${responded} people responded`}>
              <Chip
                label={responded}
                variant='filled'
                color='primary'
                sx={{ color: 'success.main' }}
                size='small'
                icon={<ArrowUpwardIcon />}
              />
            </Tooltip>
          </Box>
        </div>

        {horizontal
          ? <div className='event-card-content-container'>{content}</div>
          : content
        }
      </Link>
    </Badge>
  )
}

export default EventCardPreview