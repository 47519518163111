import React from 'react'
import { Button, Box } from '@mui/material'
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Visibility as VisibilityIcon
} from '@mui/icons-material';

import EditableTextField from './EditableTextField';

const SectionActions = ({ title, onDelete, onNameChange, disabled, sectionId }) => {
  return (
    <>
      <EditableTextField
        disabled={disabled}
        text={title}
        onChange={onNameChange}
      />

      <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, gap: 1 }}>
        {sectionId && (
          <>
            <Button
              disabled={disabled}
              variant="contained"
              color='secondary'
              endIcon={<AddIcon />}
              size='small'
              href={`/event/create?sectionId=${sectionId}&sectionName=${title}`}
            >Add new event</Button>
            <Button
              disabled={disabled}
              variant="outlined"
              color='warning'
              endIcon={<VisibilityIcon />}
              size='small'
              target="_blank"
              href={`https://staging.eventor.com.cy/category/${sectionId}`}
            >View in staging</Button>
            <Button
              disabled={disabled}
              variant="outlined"
              color='warning'
              endIcon={<VisibilityIcon />}
              size='small'
              target="_blank"
              href={`https://eventor.com.cy/category/${sectionId}`}
            >View in production</Button>
          </>
        )}
        <Button
          disabled={disabled}
          variant="outlined"
          color='error'
          onClick={onDelete}
          endIcon={<DeleteIcon />}
          size='small'
        >Delete section</Button>
      </Box>
    </>
  )
}

export default SectionActions