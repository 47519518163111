import React, { useMemo, useState, useEffect } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  IconButton,
  Paper,
  Typography,
  Tooltip,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import { useHistory } from 'react-router-dom';
import { formatDateTime, getEventImage } from './utils.js';
import { useEvents, useFilterData } from './hooks/index.js';
import SelectTableCell from './SelectTableCell.jsx';
import CategoriesSelect from './CategoriesSelect.jsx';

const ORDER = {
  ASC: 'asc',
  DESC: 'desc',
}

// Helper functions for session storage
const STORAGE_KEY = 'eventor-events-preferences';

const loadStateFromStorage = () => {
  try {
    const storedData = sessionStorage.getItem(STORAGE_KEY);
    return storedData ? JSON.parse(storedData) : null;
  } catch (error) {
    console.error('Error loading state from session storage:', error);
    return null;
  }
};

const saveStateToStorage = (state) => {
  try {
    sessionStorage.setItem(STORAGE_KEY, JSON.stringify(state));
  } catch (error) {
    console.error('Error saving state to session storage:', error);
  }
};

const Events = () => {
  const savedState = loadStateFromStorage();
  const [ page, setPage ] = useState(savedState?.page || 0);
  const [ order, setOrder ] = useState(savedState?.order || ORDER.DESC);
  const [ orderBy, setOrderBy ] = useState(savedState?.orderBy || null);
  const [ selectedFilters, setSelectedFilters ] = useState(
    savedState?.selectedFilters || { cities: [], categories: [] }
  );

  const history = useHistory()
  // filters = { categories: [{id: <Integer>, name: <String>, deleted: <Integer>}], cities: [<String>] }
  const { isLoading: loadingFilters, filters } = useFilterData();
  const { events, totalEvents, isLoading, refresh } = useEvents({ page, orderBy, order, ...selectedFilters });

  // Save state to session storage whenever relevant state changes
  useEffect(() => {
    saveStateToStorage({
      page,
      order,
      orderBy,
      selectedFilters
    });
  }, [ page, order, orderBy, selectedFilters ]);

  const onFilterChange = (filter, options) => {
    setSelectedFilters({
      ...selectedFilters,
      [filter]: options
    });
    // Reset to first page when filter changes
    setPage(0);
  }

  // * TablePagination component is 0-based, while the API is 1-based
  const onPageChange = (_event, newPage) => setPage(newPage)

  // Fucntion handling sorting for events
  const sortHandler = field => () => {
    const isAsc = orderBy === field && order === ORDER.ASC;
    setOrder(isAsc ? ORDER.DESC : ORDER.ASC);
    setOrderBy(field);
  }

  const citiesFilterOptions = useMemo(
    () => filters?.cities?.map(city => ({ value: city, label: city })) || [],
    [ filters?.cities ]
  );

  // Function to check if any filters or sorting are applied
  const hasActiveFilters = () => {
    return (
      selectedFilters.cities.length > 0 ||
      selectedFilters.categories.length > 0 ||
      orderBy !== null
    );
  };

  // Function to clear all filters and sorting
  const clearFilters = () => {
    setPage(0);
    setOrder(ORDER.DESC);
    setOrderBy(null);
    setSelectedFilters({ cities: [], categories: [] });
  };

  const handleCategoriesChange = (categories) => onFilterChange('categories', categories);

  return (
    <Container>
      <Typography variant='h1'>All Events</Typography>

      <Paper sx={{ p: 2, mb: 2, mt: 2 }}>
        <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
          <Box sx={{ minWidth: 200 }}>
            <CategoriesSelect
              hasLabel={true}
              fullWidth={true}
              defaultValue={selectedFilters.categories}
              value={selectedFilters.categories}
              setValue={handleCategoriesChange}
            />
          </Box>

          {hasActiveFilters() && (
            <Button
              startIcon={<ClearAllIcon />}
              variant="outlined"
              color="warning"
              size="small"
              onClick={clearFilters}
            >
              Clear Filters
            </Button>
          )}
        </Stack>
      </Paper>

      <TableContainer
        component={Paper}
        sx={{
          mt: 2,
          maxHeight: '70vh',
          '& .MuiTableCell-head, & .MuiTablePagination-root': {
            backgroundColor: 'primary.dark',
            color: 'white',
            textWrap: 'nowrap',
          }
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Tooltip title="Refresh events list">
                  <IconButton aria-label='refresh' color='warning' onClick={refresh} sx={{ mr: 1 }}>
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell align="center">ID</TableCell>
              <TableCell align="center">Title</TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "start_date"}
                  direction={order}
                  onClick={sortHandler("start_date")}
                >
                  Start Date
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "end_date"}
                  direction={order}
                  onClick={sortHandler("end_date")}
                >
                  End Date
                </TableSortLabel>
              </TableCell>
              <SelectTableCell
                label={'City'}
                options={citiesFilterOptions}
                onChange={(options) => onFilterChange('cities', options)}
                selected={selectedFilters.cities}
                loading={loadingFilters}
              />
              <TableCell align="center">Source</TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "responded"}
                  direction={order}
                  onClick={sortHandler("responded")}
                >
                  Responded
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={orderBy === "date_added"}
                  direction={order}
                  onClick={sortHandler("date_added")}
                >
                  Date Added
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && events.map((event) => (
              <TableRow
                key={`event-${event.id}`}
                className="clickable-row"
                onClick={(e) => {
                  // * Prevent navigation when clicking on a link inside the row
                  if (e.nativeEvent.target !== e.nativeEvent.target.parentElement.querySelector('td > a')) {
                    history.push(`/event/${event.id}`)
                  }
                }}
              >
                <TableCell className='thumbnail-cell' padding="none">
                  <img
                    height={70}
                    src={getEventImage(event?.image_url)}
                    alt=""
                    referrerPolicy="no-referrer"
                  />
                </TableCell>
                <TableCell align="center">{event.id}</TableCell>
                <TableCell align="left">{event.title}</TableCell>
                <TableCell align="center">{formatDateTime(event.start_date)}</TableCell>
                <TableCell align="center">{formatDateTime(event.end_date)}</TableCell>
                <TableCell align="center">{event.city}</TableCell>
                {/* <TableCell align="center">
                  {event.categories.map((category) => (<Chip key={`${event.id}-${category}`} label={category} />))}
                </TableCell> */}
                <TableCell align="center">
                  <a target='_blank' rel="noreferrer" href={event.source_url}>Source</a>
                </TableCell>
                <TableCell align="center">{event.responded}</TableCell>
                <TableCell align="center">{formatDateTime(event.date_added)}</TableCell>
              </TableRow>
            ))}

            {isLoading && (
              <TableRow>
                <TableCell sx={{ p: 2 }} colSpan={8} align='center'>
                  <CircularProgress />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          sx={{ position: 'sticky', bottom: 0 }}
          count={totalEvents}
          rowsPerPage={events?.length}
          rowsPerPageOptions={[]}
          page={page}
          onPageChange={onPageChange}
        />
      </TableContainer>
    </Container>
  )
}
export default Events;