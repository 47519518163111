import { CognitoUserPool } from "amazon-cognito-identity-js";
import { POOL_DATA } from "./constants";

export function getCurrentCognitoUser() {
  const userPool = new CognitoUserPool(POOL_DATA);
  const cognitoUser = userPool.getCurrentUser();
  return cognitoUser
}

export function logout() {
  const cognitoUser = getCurrentCognitoUser();

  if (cognitoUser) {
    cognitoUser.getSession(() => {
      cognitoUser.globalSignOut({
        onSuccess: function() {
          console.log('Successfully logged out');
          window.location.href = '/';
        },
        onFailure: function(err) {
          console.log(err)
        }
      })
    })
  } else {
    window.location.href = '/';
  }
}

export function formatDate(date) {
  if (!date) return
  let d = new Date(date);
  let month = d.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let day = d.getDate()
  day = day < 10 ? '0' + day : day
  return (`${day}/${month}/${d.getFullYear()}`)
}

export function formatDateTime(date) {
  if (!date) return
  let d = new Date(date);
  let month = d.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let day = d.getDate()
  day = day < 10 ? '0' + day : day
  let time = [ d.getHours(), d.getMinutes() ].map((a) => (a < 10 ? '0' + a : a)).join(':');
  return (`${day}/${month}/${d.getFullYear()} ${time}`)
}

export function formatISODateTime(date) {
  if (!date) return
  let d = new Date(date);
  let month = d.getMonth() + 1
  month = month < 10 ? '0' + month : month
  let day = d.getDate()
  day = day < 10 ? '0' + day : day
  let time = [ d.getHours(), d.getMinutes() ].map((a) => (a < 10 ? '0' + a : a)).join(':');
  return (`${d.getFullYear()}-${month}-${day}T${time}`)
}

export function dateStringToUTCString(dateString) {
  if (!dateString) {
    return null
  }
  const d = new Date(dateString)
  const ISOdateString = d.toISOString()
  return ISOdateString.split('.')[0]
}

export function formatURL(str) {
  return str?.indexOf('http') === -1 ? `https://${str}` : str;
}

export function throttleFunctions(fns) {
  return Promise.all(fns.map((fn, i) => new Promise(res => setTimeout(async () => res(await fn()), i * 500))))
}

export function getEventImage(image_url, resolution = 'low-res') {
  if (!image_url) {
    return null
  }
  return `https://eventor-scraped-images.s3.eu-west-2.amazonaws.com/${resolution}/${image_url}.webp`
}

export async function fileToBase64String(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64String = reader.result.split(',')[1]
      resolve(base64String)
    }
    reader.onerror = (error) => {
      console.error('Error converting file to base64:', error)
      reject(error)
    }
  })
}

export function registerServiceWorker() {
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV !== 'production') {
    return
  }
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(`./service-worker.js`).then(
      registration => {
        console.log('Service worker registration succeeded:', registration);
      }, error => {
        console.error(`Service worker registration failed: ${error}`);
      }
    );
  } else {
    console.warning('Service workers are not supported.');
  }
}

export const formatEventDateRange = (start_date, end_date) => {
  const start = new Date(start_date)
  const end = new Date(end_date)

  const sameDay = start.toDateString() === end.toDateString()
  const sameMonth = start.getMonth() === end.getMonth()
  const sameYear = start.getFullYear() === end.getFullYear()

  const formatNumber = (num) => (num < 10 ? '0' + num : num)

  if (!end_date) {
    return formatDateTime(start)
  } else if (sameDay) {
    return `${formatNumber(start.getDate())}/${formatNumber(start.getMonth() + 1)}/${start.getFullYear()} ${formatNumber(start.getHours())}:${formatNumber(start.getMinutes())}-${formatNumber(end.getHours())}:${formatNumber(end.getMinutes())}`
  } else if (!sameMonth || !sameYear) {
    return `${formatNumber(start.getDate())}/${formatNumber(start.getMonth() + 1)}/${start.getFullYear()}-${formatNumber(end.getDate())}/${formatNumber(end.getMonth() + 1)}/${end.getFullYear()}`
  } else {
    return formatDate(start)
  }
}