import React, { useState } from 'react'
import { useLocation } from 'react-router-dom';

import {
  Alert,
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

import EventImage from './EventImage'
import { dateStringToUTCString, formatISODateTime } from './utils'
import { useAwsLambdaContext } from './context/AwsLambda'
import NewEventPreviewAlert from './NewEventPreviewAlert'
import Select from './Select'
import { useSnackbar } from './context/Snackbar';

const FIELDS = [
  { name: 'start_date', label: 'Start date', required: true, type: 'datetime-local' },
  { name: 'end_date', label: 'End date', required: false, type: 'datetime-local' },
  { name: 'title', label: 'Title', required: true, type: 'text' },
  { name: 'responded', label: 'Responded (Priority - Default = 0)', required: false, type: 'number' },
  { name: 'organiser_name', label: 'Organiser name', required: false, type: 'text' },
  { name: 'location', label: 'Location', required: false, type: 'text' },
  { name: 'city', label: 'City', required: true, type: 'text', optionskey: 'cities' },
  { name: 'ticket_link', label: 'Ticket URL', required: false, type: 'url', placeholder: 'https://example.com' },
  { name: 'description', label: 'Description', required: false, type: 'text', multiline: true, minRows: 3 },
  { name: 'categories', label: 'Categories', required: false, type: 'text', multiple: true, optionskey: 'categories' },
  { name: 'source_url', label: 'Source URL', required: false, type: 'url', placeholder: 'https://example.com' },
]

const SourceUrlAdornment = ({ onClick }) => (
  <InputAdornment position="end">
    <IconButton
      aria-label="check if event already exists in database"
      onClick={onClick}
      edge="end"
      color='secondary'
    >
      <ManageSearchIcon />
    </IconButton>
  </InputAdornment>
)

const AddEvent = () => {
  const [ imageFile, setImageFile ] = useState(null)
  const [ createdEventId, setCreatedEventId ] = useState(null)
  const { searchEvent, createEvent, isLoading } = useAwsLambdaContext()
  const [ categories, setCategories ] = useState([])
  const [ city, setCity ] = useState('')
  const [ sourceUrlIsFilled, setSourceUrlIsFilled ] = useState(false)
  const { showSnackbar } = useSnackbar()
  const location = useLocation()

  const queryParams = new URLSearchParams(location.search)
  const sectionId = queryParams.get('sectionId')
  const sectionName = queryParams.get('sectionName')

  const onTextFieldChange = (field, e) => {
    if (field.name === 'source_url') {
      setSourceUrlIsFilled(!!e.target.value)
    }
  }

  const onSourceUrlCheckClick = async () => {
    const formElements = document.querySelector('main form').elements
    const sourceUrl = formElements['source_url']?.value
    // check via API if event already exists
    const response = await searchEvent(sourceUrl, { suppressNotFound: true })
    const event = response?.[0]
    showSnackbar({
      severity: event ? 'success' : 'info',
      message: event
        ? <span>Event already exists: <a href={`/event/${event.id}`}>#{event.id}</a></span>
        : 'Event does not exist',
      autoHideDuration: 10000
    })
  }

  const onFormReset = (e) => {
    const form = e.target
    form.reset()
    setImageFile(null)
    setCity('')
    setCategories([])
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const form = e.target
    const formElements = form.elements
    // handle categories differently because Mui Select returns an array of [Object object]
    const data = FIELDS.map(({ name, type }) => ({
      name,
      value: name === 'categories'
        ? (categories?.map(c => c?.id) || [])
        : type === 'datetime-local'
          // convert to UTC string
          ? dateStringToUTCString(formElements[name]?.value)
          : formElements[name]?.value
    }))

    // Add section ID to the data if available
    if (sectionId) {
      data.push({ name: 'section_id', value: sectionId });
    }

    const response = await createEvent(data, imageFile)

    if (response?.eventId) {
      setCreatedEventId(response.eventId)
      // Clear form
      onFormReset(e)
    }
  }

  return (
    <Container>
      {isLoading && (
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100vw',
          position: 'fixed',
          top: 0,
          left: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 9999,
        }}>
          <CircularProgress />
        </Box>
      )}

      <Typography variant='h1'>Add new event</Typography>

      {sectionId && (
        <Alert
          severity="info"
          variant="outlined"
          sx={{
            color: 'text.primary',
            mt: 2,
            mb: 3,
            justifyContent: 'center',
            alignItems: 'center',
            justifySelf: 'center',
          }}
        >
          <Typography variant="h6" sx={{ fontSize: '120%' }}>
              Adding to section:
            <Chip
              sx={{ ml: 2, px: 2, fontFamily: 'inherit', fontWeight: 'bold', fontSize: 'inherit' }}
              label={`${sectionName} (ID: ${sectionId})`}
              color="secondary"
              variant="filled"
              size="large"
            />
          </Typography>
        </Alert>
      )}

      <NewEventPreviewAlert onClose={() => setCreatedEventId(null)} eventId={createdEventId} />

      <Box component='form' onSubmit={onSubmit} onReset={onFormReset} sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '1rem',
        maxWidth: '400px',
        mt: '1rem',
        mx: 'auto',
        '& .Mui-required .MuiFormLabel-asterisk': { color: 'error.main' },
      }}>
        <EventImage file={imageFile} onUpload={e => setImageFile(e.target.files[0])} />

        {FIELDS.map(field => (
          field.optionskey
            ? <Select
              fullWidth
              key={field.name}
              {...field}
              value={field.name === 'categories' ? categories : city}
              setValue={field.name === 'categories' ? setCategories : setCity}
            />
            : <TextField
              fullWidth
              onChange={e => onTextFieldChange(field, e)}
              color='secondary'
              key={field.name}
              {...field}
              {...(field.name === 'source_url' && sourceUrlIsFilled ? {
                InputProps: { endAdornment: <SourceUrlAdornment onClick={onSourceUrlCheckClick} /> },
              } : {})}
              {...(field.type === 'datetime-local' ? {
                InputLabelProps: { shrink: true },
                inputProps: { min: formatISODateTime(new Date()) },
              } : {})}
            />
        ))}

        <Box sx={{
          position: 'sticky',
          bottom: '2rem',
          zIndex: 1,
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          gap: 2,
          mt: 2,
        }}>
          <Button
            type='reset'
            variant='outlined'
            sx={{ bgcolor: 'background.default', '&:hover': { bgcolor: 'background.paper' } }}
            color='warning'
          >Clear</Button>
          <Button type='submit' variant='contained' color='secondary'>Submit</Button>
        </Box>
      </Box>
    </Container>
  )
}

export default AddEvent