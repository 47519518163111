import React, { useRef } from 'react';
import {
  Checkbox,
  TextField,
  Autocomplete,
  Chip,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { useFilterData } from './hooks/index';

const icon = <CheckBoxOutlineBlankIcon color='secondary' fontSize="small" />;
const checkedIcon = <CheckBoxIcon color='secondary' fontSize="small" />;

const CategoriesSelect = ({
  sx = {},
  hasLabel,
  fullWidth,
  value,
  setValue,
  inputRef,
  defaultValue,
}) => {
  const { filters, isLoading } = useFilterData()
  const initialisedRef = useRef(false)

  if (!initialisedRef.current) {
    setTimeout(() => setValue(defaultValue), 0)
    initialisedRef.current = true
  }

  return (
    <Autocomplete
      sx={sx}
      multiple
      ref={inputRef}
      disableCloseOnSelect
      fullWidth={fullWidth}
      size='small'
      loading={isLoading}
      options={filters?.categories || []}
      value={value}
      defaultValue={defaultValue}
      onChange={(e, value) => setValue(value)}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(o, v) => o.id.toString() === v.id.toString()}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li key={key} {...optionProps}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={selected}
            />
            {option.name}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField {...params} label={hasLabel ? 'Categories' : undefined} color='secondary' />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const { key, ...tagProps } = getTagProps({ index });
          return (
            <Chip
              key={key}
              variant="contained"
              color="secondary"
              label={option.name}
              size="small"
              {...tagProps}
            />
          );
        })
      }
    />
  )
}

export default CategoriesSelect